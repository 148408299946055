import { useCallback, useEffect, useRef } from "react";

export const useAnimationFrame = (callback: (arg0: number) => void) => {
  const requestRef = useRef<number>();
  const previousTimeRef = useRef<number>();

  const animate = useCallback(
    (time: number) => {
      if (previousTimeRef.current != undefined) {
        const deltaTime = time - previousTimeRef.current;
        callback(deltaTime);
      }
      previousTimeRef.current = time;
      requestRef.current = requestAnimationFrame(animate);
    },
    [callback]
  );

  useEffect(() => {
    requestRef.current = requestAnimationFrame(animate);
    return () => {
      // console.info("cancel animation frame");
      cancelAnimationFrame(requestRef.current!);
    };
  }, [animate]);
};
