import { Entry } from "contentful";
import { AnimatePresence, motion } from "framer-motion";

import EscapeNewLine from "~/components/common/escape-new-line";
import HighlightedText from "~/components/common/highlighted-text";
import Editorials from "~/types/editorials";
import contentfulUtils from "~/utils/contentful-utils";
import { isActiveEntry } from "~/utils/editorial-utils";

import EditorialLink from "../editorial-link/editorial-link";
import styles from "./timed-editorial-carousel-slide.module.scss";

type Props = {
  entry: Entry<Editorials.TimedEditorialCarouselSlide>;
};

export default function TimedEditorialCarouselSlide({ entry }: Props) {
  if (!isActiveEntry(entry)) return null;

  const inspectorMode = contentfulUtils.useInspectorMode(entry);

  return (
    <AnimatePresence mode="wait">
      <motion.div
        key={entry.sys.id + "wrapperText"}
        initial={{ x: -10, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        exit={{ x: 10, opacity: 0 }}
        className={styles.wrapperText}
      >
        {entry.fields?.bigTitle && (
          <motion.p
            key={entry.sys.id + "bigTitle"}
            {...inspectorMode?.getProps("bigTitle")}
            className={styles.bigTitle}
          >
            <HighlightedText text={entry.fields.bigTitle!} />
          </motion.p>
        )}
        {entry.fields?.bigText && (
          <motion.p key={entry.sys.id + "bigText"} {...inspectorMode?.getProps("bigText")} className={styles.bigText}>
            <EscapeNewLine text={entry.fields.bigText} />
          </motion.p>
        )}
        {entry.fields?.paragraph && (
          <motion.p key={entry.sys.id + "p"} {...inspectorMode?.getProps("paragraph")} className={styles.paragraph}>
            <EscapeNewLine text={entry.fields.paragraph} />
          </motion.p>
        )}
        {entry.fields?.link && (
          <motion.div key={entry.sys.id + "link"} className={styles.wrapperLink}>
            <EditorialLink entry={entry.fields?.link} lookAsButton="secondary" />
          </motion.div>
        )}
      </motion.div>
    </AnimatePresence>
  );
}
